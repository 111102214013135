var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "title-bar-header",
      attrs: { "data-cy": "title-bar-header" }
    },
    [
      _vm._t("prepend"),
      _vm._v(" "),
      _c("h1", {
        staticClass: "title-bar-header__title",
        domProps: { textContent: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _vm._t("append")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }