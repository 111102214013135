var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "NuxtLink",
    {
      staticClass: "breadcrumb",
      attrs: { "aria-label": "Breadcrumb", to: _vm.breadcrumb.route }
    },
    [
      _c(_vm.breadcrumbIconComponent, {
        tag: "Component",
        attrs: { "fill-color": _vm.greenColor, width: "16", height: "16" }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "breadcrumb__title",
        domProps: { textContent: _vm._s(_vm.breadcrumb.title) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }