var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "title-bar", attrs: { "data-cy": "title-bar" } },
    [
      _c("TitleBarHeader", {
        attrs: { title: _vm.title },
        scopedSlots: _vm._u(
          [
            {
              key: "prepend",
              fn: function() {
                return [_vm._t("prependHeader")]
              },
              proxy: true
            },
            {
              key: "append",
              fn: function() {
                return [
                  _vm._t("prependBadge"),
                  _vm._v(" "),
                  _vm.isReadOnly ? _c("ReadOnlyBadge") : _vm._e()
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c(
        "TitleBarActions",
        [_vm._t("subActions"), _vm._v(" "), _vm._t("mainAction")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }